import keytrudaSrc from "../assets/images/KEYTRUDA_Logo.png";
import prevymisSrc from "../assets/images/PREVYMIS_US_Tablet_Logo_RGB.svg";
import weliregSrc from "../assets/images/WELIREG_Logo.png";
import zinplavaSrc from "../assets/images/ZINPLAVA_Logo.png";
import insurance from '../assets/images/icons/binoculars.png';
import coPay from '../assets/images/icons/insurance.png';
import referral from '../assets/images/icons/patient-assistance.png';
import magnifyingGlass from '../assets/images/icons/benefit-investigations.png';
import winrevairSrc from "../assets/images/winrevair_logo.png";

const hcp = {
    sectionTitle: 'The Merck Access Program (MAP) may be able to help answer questions about access and support, including:',
    audience: 'hcp',
    icons: [
          {
            icon: magnifyingGlass,
            alt: '',
            copy: 'Benefit investigations and the prior authorization and appeals processes',
          },
          {
            icon: insurance,
            alt: '',
            copy: 'Insurance coverage for patients',
          },
          {
            icon: coPay,
            alt: '',
            copy: 'Co-pay assistance for eligible patients',
          },
          {
            icon: referral,
            alt: '',
            copy: 'Referral to the Merck Patient Assistance Program for eligibility  determination',
            subcopy: '(Provided through the Merck Patient Assistance Program, Inc.)',
          },
        ],
    
    cards: {
      title: "To Learn More, Choose a Product Below",
      cards: [
        {
          logo: keytrudaSrc,
          alt: "",
          hrefButton: "https://www.merckaccessprogram-keytruda.com/",
          links: [
            {
              title: "Medication Guide",
              url: "https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_mg.pdf",
              alt: ''
            },
            {
              title: "Prescribing Information",
              url: "https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf",
              alt: ''
            }
          ]
        },
        {
          logo: prevymisSrc,
          alt: "",
          hrefButton: "https://www.merckaccessprogram-prevymis.com",
          links: [
            {
              title: "Patient Information",
              url: "https://www.merck.com/product/usa/pi_circulars/p/prevymis/prevymis_ppi.pdf",
              alt: ''
            },
            {
              title: "Prescribing Information",
              url: "https://www.merck.com/product/usa/pi_circulars/p/prevymis/prevymis_pi.pdf",
              alt: ''
            },
            {
              title: "Instructions for Use",
              url: "https://www.merck.com/product/usa/pi_circulars/p/prevymis/prevymis_ifu.pdf",
              alt: ''
            }
          ]
        },
        {
          logo: weliregSrc,
          alt: "",
          hrefButton: "https://www.merckaccessprogram-welireg.com/hcp/",
          links: [
            {
              title: "Medication Guide",
              url: "https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf",
              alt: ''
     
            },
            {
              title: "Prescribing Information",
              url: "https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf",
              alt: ''
            }
          ],
          description: 'Please read the accompanying <a href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf" target="_blank"> Medication Guide</a> for WELIREG, including an important warning about harm to an unborn baby, and discuss it with your doctor. The physician <a href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf" target="_blank">Prescribing Information</a> also is available.',
        },
        {
          logo: winrevairSrc,
          alt: "",
          hrefButton: "https://merckaccessprogram-winrevair.com/",
          links: [
            {
              title: 'Patient Information',
              url: 'https://www.merck.com/product/usa/pi_circulars/w/winrevair/winrevair_ppi.pdf',
              alt: ''
            },
            {
              title: 'Prescribing Information',
              url: 'https://www.merck.com/product/usa/pi_circulars/w/winrevair/winrevair_pi.pdf',
              alt: ''
            },
            {
              title: "Instructions for Use (1-vial kit, 2-vial kit)",
              url: "https://www.merck.com/product/usa/pi_circulars/w/winrevair/winrevair_ifu_1-vial_2-vial_kits.pdf",
              alt: ''
            }
          ],
        },
        // {
        //   logo: zinplavaSrc,
        //   alt: "",
        //   hrefButton: "https://www.merckaccessprogram-zinplava.com/",
        //   links: [
        //     {
        //       title: "Patient Information",
        //       url: "https://www.merck.com/product/usa/pi_circulars/z/zinplava/zinplava_ppi.pdf",
        //       alt: ''
        //     },
        //     {
        //       title: "Prescribing Information",
        //       url: "https://www.merck.com/product/usa/pi_circulars/z/zinplava/zinplava_pi.pdf",
        //       alt: ''
        //     }
        //   ]
        // },
      ],
     }
  
  };
  
export default hcp
